import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'placeholderReplace',
    standalone: true
})
export class PlaceholderReplacePipe implements PipeTransform {

  public transform(text: string, params: { [key: string]: string | number }): string {
    Object.keys(params).forEach((key) => text = text.replaceAll(`{{${key}}}`, params[key].toString()));
    return text;
  }

}
