/* tslint:disable */
/* eslint-disable */
export enum PrebidCreationConflictType {
  LotNotAvailable = 'lot_not_available',
  PrebidsNotActive = 'prebids_not_active',
  BidAmountMustNotContainDecimalPlaces = 'bid_amount_must_not_contain_decimal_places',
  BidAmountLowerThanStartingBid = 'bid_amount_lower_than_starting_bid',
  BidAmountNotAlignedToBidIncrements = 'bid_amount_not_aligned_to_bid_increments',
  ExistingBidIsHigher = 'existing_bid_is_higher',
  BidderTermsAndConditionsNotAccepted = 'bidder_terms_and_conditions_not_accepted',
  InsufficientCreditToSubmit = 'insufficient_credit_to_submit'
}
