import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, EMPTY, interval, Observable, of, switchMap, tap } from 'rxjs';
import { GeneratedUserProfileService } from '@shared/generated/services';
import { UserProfileDto } from '@shared/generated/models/user-profile-dto';
import { nameof } from 'ts-simple-nameof';
import { createJsonPatchPath, navigateToNewCulture } from '@shared/misc/helpers';
import { AddressDto } from '@shared/generated/models/address-dto';
import { Operation } from '@shared/generated/models/operation';
import { AutoSubmitPrebidType } from '@shared/generated/models/auto-submit-prebid-type';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { mergeDeep } from 'inova-shared';
import { isPlatformBrowser } from '@angular/common';
import { catchError, filter } from 'rxjs/operators';
import { UserVerificationState } from '@shared/generated/models/user-verification-state';
import { ExternalVerificationInfoDto } from '@shared/generated/models/external-verification-info-dto';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { PlaceholderReplacePipe } from '@shared/pipes/placeholder-replace.pipe';
import { TranslationService } from '@shared/services/translation.service';
import { UserProfileConflictType } from '@shared/generated/models';
import { SettingsTab } from '../../pages/my/settings/settings-tabs/settings-tab.enum';

@Injectable({ providedIn: 'root' })
export class UserProfileService {

  public readonly userProfile$: BehaviorSubject<UserProfileDto | undefined> = new BehaviorSubject<UserProfileDto | undefined>(undefined);
  public readonly selectedCurrency$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  public lastVerificationMailSent?: DateTime;


  constructor(private generatedService: GeneratedUserProfileService,
              private router: Router,
              private toastr: ToastrService,
              private translationService: TranslationService,
              private translate: TranslateService,
              private replacePipe: PlaceholderReplacePipe,
              @Inject(PLATFORM_ID) private platformId: string) {
    if (isPlatformBrowser(this.platformId)) {
      interval(5000).pipe(
        filter(() => !!this.userProfile$.value), // TODO Wir können nur daran feststellen, ob wir eingeloggt sind... das sollte überarbeitet werden!!!
        switchMap(() => this.generatedService.getUserProfile()
          .pipe(catchError(() => EMPTY))),
        filter(() => !!this.userProfile$.value) // Total bescheuert, aber es kann sein, dass der Reqest so lange dauert, dass wir in der zwischenzeit ausgeloggt wurden, dann dürfen wir uns nicht wieder einloggen
      ).subscribe((profile) => this.userProfile$.next(profile));
    }
    this.userProfile$.subscribe((d) => this.selectedCurrency$.next(d?.settings.additional.currency ?? undefined));
  }

  // skipping loading of culture in bootstrap-process
  public getUserProfile(skipSettingCulture: boolean = true): Observable<UserProfileDto> {
    return this.generatedService.getUserProfile()
      .pipe(tap((profile) => {
        this.userProfile$.next(profile);
        // Sentry.setUser({id: profile.id}); TODO - we need to add an option to accept or decline collection of personalized data (or at least inform the user about it)
        if (!skipSettingCulture && profile && profile.settings.cultureName !== this.translate.currentLang) {
          navigateToNewCulture(this.router, profile?.settings.cultureName)
            .then(() => isPlatformBrowser(this.platformId) && location.reload());
        }
      }));
  }

  public setCulture(culture: string): Observable<UserProfileDto> {
    return this.patchUser([{
      path: createJsonPatchPath(nameof<UserProfileDto>((u) => u.settings.cultureName)),
      op: 'replace',
      value: culture
    }]);
  }

  public setCurrency(currency: string): Observable<UserProfileDto> {
    return this.patchUser([{
      path: createJsonPatchPath(nameof<UserProfileDto>((u) => u.settings.additional.currency)),
      op: 'replace',
      value: currency
    }]);
  }

  public patchAddresses(addresses: AddressDto[]): Observable<UserProfileDto> {
    addresses.forEach((a) => a.state = a.state ?? '');
    return this.patchUser([
      {
        path: createJsonPatchPath(nameof<UserProfileDto>((u) => u.addresses.primary)),
        op: 'replace',
        value: addresses[0]
      },
      {
        path: createJsonPatchPath(nameof<UserProfileDto>((u) => u.addresses.others)),
        op: 'replace',
        value: addresses.slice(1)
      }]);
  }

  public patchAdditionalSettings(settings: {
    autosubmitPrebids?: AutoSubmitPrebidType | null,
    showOffensiveMedia?: boolean
  }): Observable<UserProfileDto> { // displayUserCurrency: boolean,
    const patchArray = [];
    if (settings.autosubmitPrebids !== undefined) {
      patchArray.push({
        path: createJsonPatchPath(nameof<UserProfileDto>((u) => u.settings.additional.autoSubmitPrebid)),
        op: 'replace',
        value: settings.autosubmitPrebids
      });
    }
    if (settings.showOffensiveMedia !== undefined) {
      patchArray.push({
        path: createJsonPatchPath(nameof<UserProfileDto>((u) => u.settings.additional.showOffensiveContents)),
        op: 'replace',
        value: settings.showOffensiveMedia
      });
    }
    if (!patchArray.length) {
      return of(this.userProfile$.value!);
    }
    return this.patchUser(patchArray);
  }

  public resendVerificationMail(): Observable<void> {
    const countSecondsAgo = Math.abs(this.lastVerificationMailSent?.diffNow('seconds').seconds ?? 60);
    if (countSecondsAgo < 60) {
      this.toastr.info(this.replacePipe.transform(this.translationService.translation.pages.account.settings.verification.resendMailWaitNotification, { secondsLeft: Math.ceil(60 - countSecondsAgo) }));
      return of(void 0);
    }
    this.lastVerificationMailSent = DateTime.now();
    return this.generatedService.resendVerificationMail()
      .pipe(tap(() => this.toastr.success(this.translationService.translation.pages.account.settings.verification.resendMailSuccessNotification)))
      .pipe(catchError((e) => {
        this.toastr.error(this.translationService.translation.shared.notification.unknownError);
        throw e;
      }));
  }

  public patchUserVerificationState(state: UserVerificationState, token?: string): Observable<UserProfileDto> {
    return this.patchUser([{
      path: createJsonPatchPath(nameof<UserProfileDto>((u) => u.verificationState)),
      op: 'replace',
      value: state.replaceAll('_', '')
    }], token)
      .pipe(catchError((error) => {
        let msg = this.translationService.translation.shared.notification.unknownError;
        if (typeof error.error === 'string') {
          switch (error.error) {
            case UserProfileConflictType.InvalidSecurityToken:
              msg = this.translationService.translation.pages.account.settings.verification.patchUserStateNotification.invalidToken;
              break;
            case UserProfileConflictType.NoActiveVerification:
              msg = this.translationService.translation.pages.account.settings.verification.patchUserStateNotification.noActiveVerification;
              break;
            case UserProfileConflictType.VerificationAlreadyActive:
              msg = this.translationService.translation.pages.account.settings.verification.patchUserStateNotification.verificationAlreadyActive;
              break;
            case UserProfileConflictType.UnexpectedVerificationState:
              msg = this.translationService.translation.pages.account.settings.verification.patchUserStateNotification.unexpectedVerificationState;
              break;
            case UserProfileConflictType.VerificationNotAllowed:
              msg = this.translationService.translation.pages.account.settings.verification.patchUserStateNotification.verificationNotAllowed;
              break;
          }
        } else if (error.error?.errors && Object.keys(error.error.errors).some((k) => k.includes('.Addresses'))) {
          msg = this.translationService.translation.pages.account.settings.verification.patchUserStateNotification.addressDataIncompleteNotification;
          this.router.navigate([], { queryParams: { tab: SettingsTab.Addresses }, queryParamsHandling: 'merge' });
        }
        this.toastr.error(msg);
        throw error;
      }));
  }

  public getExternalVerificationInfo(token: string): Observable<ExternalVerificationInfoDto> {
    return this.generatedService.getExternalVerificationInfo({ token: token })
      .pipe(catchError((error) => {
        this.toastr.error(this.translationService.translation.pages.account.settings.verification.external.getVerificationError);
        throw error;
      }));
  }

  private patchUser(operations: Operation[], token?: string): Observable<UserProfileDto> {
    return this.generatedService.patchUserProfile({ body: operations, securityToken: token })
      .pipe(tap((u) => this.userProfile$.next(mergeDeep(this.userProfile$.value!, u) as UserProfileDto)));
  }
}
